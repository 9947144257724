
import { Owner, Asset } from "@/interfaces";
import Vue, { PropType } from "vue";

import MyEditor from "@/components/asset/MyEditor.vue";

import http from "@/http";

export interface DataType {
  asset?: Asset;
}

export default Vue.extend({
  components: {
    MyEditor
  },
  props: {
    owner: {
      type: Object as PropType<Owner>,
      required: true
    }
  },
  data(): DataType {
    return {
      asset: {
        name: "",
        zip: "",
        address: "",
        memo: "",
        management_company: ""
      }
    };
  },
  computed: {
    rules() {
      return {
        required: [(v: string) => !!v || "必須です"]
      };
    }
  },
  methods: {
    async save(e: Asset) {
      const url = `owners/${this.owner.id}/assets`;

      const { data } = await http.post<Asset>(url, {
        name: e.name,
        zip: e.zip,
        address: e.address,
        memo: e.memo,
        management_company: e.management_company
      });

      await this.$router.push(`/owner/${this.owner.id}/assets/`);
    }
  }
});
