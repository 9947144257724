
import { Asset } from "@/interfaces";
import Vue, { PropType } from "vue";
import axios from "axios";

const prefs = [
  null,
  "北海道",
  "青森県",
  "岩手県",
  "宮城県",
  "秋田県",
  "山形県",
  "福島県",
  "茨城県",
  "栃木県",
  "群馬県",
  "埼玉県",
  "千葉県",
  "東京都",
  "神奈川県",
  "新潟県",
  "富山県",
  "石川県",
  "福井県",
  "山梨県",
  "長野県",
  "岐阜県",
  "静岡県",
  "愛知県",
  "三重県",
  "滋賀県",
  "京都府",
  "大阪府",
  "兵庫県",
  "奈良県",
  "和歌山県",
  "鳥取県",
  "島根県",
  "岡山県",
  "広島県",
  "山口県",
  "徳島県",
  "香川県",
  "愛媛県",
  "高知県",
  "福岡県",
  "佐賀県",
  "長崎県",
  "熊本県",
  "大分県",
  "宮崎県",
  "鹿児島県",
  "沖縄県"
];

export interface DataType {
  name: string;
  zip: string;
  address: string;
  memo: string;
  management_company: string;
  valid: boolean;
}

export default Vue.extend({
  props: {
    asset: {
      type: Object as PropType<Asset>,
      required: true
    }
  },
  data(): DataType {
    return {
      name: "",
      zip: "",
      address: "",
      memo: "",
      management_company: "",
      valid: false
    };
  },
  computed: {
    rules() {
      return {
        required: [(v: string) => !!v || "必須です"]
      };
    },
    zipValid(): boolean {
      return /^\d{7}$/.test(this.zip);
    }
  },
  watch: {
    async zip() {
      if (!this.zipValid) {
        return;
      }

      const zip = this.zip.substring(0, 3);

      const url = `https://yubinbango.github.io/yubinbango-data/data/${zip}.js`;

      try {
        const res = await axios.get(url);

        const json = JSON.parse(
          res.data
            .substring(0, res.data.lastIndexOf(");"))
            .replace("$yubin(", "")
        );

        const address = json[this.zip];

        if (address) {
          this.address = `${prefs[address[0]]}${address[1]}${address[2]}`;
        } else {
          this.address = "住所が見つかりませんでした";
        }
      } catch (error) {
        this.address = "通信エラーです";
      }
    }
  },
  mounted() {
    this.name = this.asset.name;
    this.zip = this.asset.zip;
    this.address = this.asset.address;
    this.memo = this.asset.memo;
    this.management_company = this.asset.management_company;
  },
  methods: {
    async click() {
      this.$emit("save", {
        name: this.name,
        zip: this.zip,
        address: this.address,
        memo: this.memo,
        management_company: this.management_company
      });
    }
  }
});
